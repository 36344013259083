import React from "react";
import styled from "styled-components";
import {mediaQuery} from "../../components/_breakpoints";

const Article = ({ title, date, description, content, PostContent }) => {
  return (
    <ArticleWrapper>
      <ArticleTitle>{title}</ArticleTitle>
      {date && <ArticleDate>{date}</ArticleDate>}
      <ArticleDescription>{description}</ArticleDescription>
      <CMSContent>
        <PostContent content={content} />
      </CMSContent>
    </ArticleWrapper>
  );
};

const ArticleWrapper = styled.div`
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  padding-bottom: 50px;
  width: 80%;
  ${mediaQuery.sm(`
    padding-bottom: 0;
  `)}
`;

const ArticleDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ArticleDate = styled.span`
  display: flex;
  position: relative;
  top: -22px;
`;

const ArticleTitle = styled.h1`
  display: flex;
`;

const CMSContent = styled.div`
  display: flex;
  max-width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

export default Article;
