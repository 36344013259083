import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import styled from "styled-components";
import Content, { HTMLContent } from "../components/Content";
import Article from "../modules/Article";
import { mediaQuery } from "../components/_breakpoints";
import BlogRoll from "../components/BlogRoll";

export const ArticleContent = ({
  contentComponent,
  description,
  date,
  index,
  content,
  title,
  helmet,
}) => {
  const [isArticlesOpen, setArticlesOpen] = useState(false);
  const handleShowHideButton = () => {
    setArticlesOpen((prev) => !prev);
  };

  const PostContent = contentComponent || Content;
  const postNumber = index + 1;
  return (
    <Section className="section">
      {helmet || ""}
      <NewestArticleWrapper
        date={date}
        index={postNumber}
        isArticlesOpen={isArticlesOpen}
      >
        <Article
          content={content}
          description={description}
          date={date}
          PostContent={PostContent}
          title={title}
        />
      </NewestArticleWrapper>
      <BlogRoll
        onShowHideButton={handleShowHideButton}
        isArticlesOpen={isArticlesOpen}
      />
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  width: 100%;
  margin-top: 60px;
  z-index: 50;
  ${mediaQuery.sm(`
    margin-left: 100px;
    margin-top: 100px;
    min-height: 40vh;
    position: relative;
  `)};
`;

const NewestArticleWrapper = styled.div`
  display: flex;
  width: 100%;
  ${(props) =>
    props.isArticlesOpen ? `margin-bottom: 250px` : `margin-bottom: 0`};
}
  ${(props) =>
    mediaQuery.sm(`
      min-height: 40vh;
      width: 100%;
      position: relative;
      top: -28px;
     &:before {
      content: '#${props.index}';
      font-size: 55px;
      margin-right: 0;
      top: 10px;
      display: flex;
      position: relative;
    }
  `)}
`;

ArticleContent.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const ArticleTemplate = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ArticleContent
        content={post.html}
        articlesCount={pageContext.articlesCount}
        index={pageContext.index}
        date={pageContext.date}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
