import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery, Link } from "gatsby";
import styled from "styled-components";
import { mediaQuery } from "./_breakpoints";
import { colorsGlobal } from "./_global";

const ArticlesHideShow = ({
  data,
  onShowHideButton,
  isArticlesOpen,
  isArticleRoll = false,
}) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <ArticlesWrapper isOpen={isArticlesOpen}>
      <HideShowButton isActive={isArticlesOpen} onClick={onShowHideButton}>
        {isArticlesOpen
          ? "SCHOWAJ LISTĘ AKTUALNOŚCI"
          : "POKAŻ LISTĘ AKTUALNOŚCI"}
      </HideShowButton>
      <Posts>
        {posts &&
          posts
            .filter((el, index) => (isArticleRoll ? index !== 0 && el : el))
            .map(({ node: post }) => (
              <LinkStyled to={post.fields.slug}>
                <ArticleItem key={post.id}>
                  <ArticleHeader>
                    <ArticleParagraph>
                      <Separator> &bull; </Separator>
                      {post.frontmatter.title}
                      <Date>{post.frontmatter.date}</Date>
                    </ArticleParagraph>
                  </ArticleHeader>
                </ArticleItem>
              </LinkStyled>
            ))}
      </Posts>
    </ArticlesWrapper>
  );
};

const Separator = styled.span`
  display: none;
  ${mediaQuery.sm(`
    display: flex;
    margin-right: 10px;
  `)}
`;

const Date = styled.span`
  display: flex;
  left: 0;
  top: 4px;
  position: absolute;
  text-align: right;
  font-size: 12px;
`;

const ArticleItem = styled.article`
  display: flex;
  position: relative;
`;

const ArticleHeader = styled.header`
  display: flex;
`;

const ArticleParagraph = styled.p`
  display: flex;
`;

const LinkStyled = styled(Link)`
  display: flex;
  color: black;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    background: ${colorsGlobal.primaryLight};
  }
`;

const HideShowButton = styled.button`
  display: flex;
  position: relative;
  transform: translateY(-100%);
  padding: 4px;
  border-top: 2px solid
    ${(props) => (props.isActive ? colorsGlobal.primary : "black")};
  border-left: 2px solid
    ${(props) => (props.isActive ? colorsGlobal.primary : "black")};
  border-right: 2px solid
    ${(props) => (props.isActive ? colorsGlobal.primary : "black")};
  border-bottom: 2px solid white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: white;
  outline: none;
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
`;

const ArticlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background: white;
  box-sizing: border-box;
  width: 100%;
  bottom: ${(props) => (props.isOpen ? 0 : `-230px`)};
  height: 250px;
  transition: all 0.228s ease-out;
  border-top: 4px solid
    ${(props) => (props.isOpen ? colorsGlobal.primary : "black")};
  ${mediaQuery.sm(`
    max-width: 73%;
    width: 80%;
    left: inherit;
  `)}
`;

const Posts = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  width: 96%;
  ${mediaQuery.sm(`
    width: 100%;
  `)}
`;

ArticlesHideShow.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default ({ ...args }) => (
  <StaticQuery
    query={graphql`
      query ArticlesRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "article-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              html
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "DD.MM.YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <ArticlesHideShow data={data} count={count} {...args} />
    )}
  />
);
